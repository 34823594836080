body {
  margin: 0;
  background-color: #E6F4F4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
  }

*::-webkit-scrollbar-track {}

*::-webkit-scrollbar-thumb {
  width: 1px;
  height: 25%;
  background-color: #1B8E95;
  border-radius: 2px;
}