.table {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader {
    border-bottom: 1px solid #ccc;
}

.tableRow {
    border-bottom: 1px solid #ccc;   
}

.tableRow:last-child {
    border-bottom: none;
}


.tableCell {
    text-align: left;
    padding: 20px 10px;
}

.tableHeaderCell {
}